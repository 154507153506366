<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Gestione MES</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content> 
      <div id="container">
        <ion-grid>
          <ion-row>
            <div  :value="d.sourceId" v-for="d,i in devices" :key="'sonda-'+i">
              <ion-col>
                <div id="device" @click="$router.push('/folder/DispositivoMes/'+d.SourceId)">{{d.SourceId}} 
                  <br>
                  <img style="width:30%;height:20%" src="images\greencube.png" v-if="d.Status=='Available'">
                  <img style="width:30%;height:20%" src="images\redcube.png" v-if="d.Status=='NotPresent'">
                </div>
              </ion-col>
            </div>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import {
    IonButtons, 
    IonCol,
    IonContent,
    IonGrid, 
    IonHeader, 
    IonMenuButton, 
    IonPage,
    IonRow, 
    IonTitle, 
    IonToolbar 
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonButtons,
      IonCol,
      IonContent,
      IonGrid,
      IonHeader,
      IonMenuButton,
      IonPage,
      IonRow,
      IonTitle,
      IonToolbar,
    },
    data : function(){
      return{
        devices:[],
      }
    },
    methods:{
      getDevices(){
        this.axios.post(this.apiUrl + 'get-devices', {}, this.headers)
        .then(async (response) => {
          this.devices=response.data.result.Output
          this.strRisultati= JSON.stringify(this.devices)
        })
      },
    },
    ionViewWillEnter: function(){
      this.getDevices();
    }
}
</script>

<style scoped>
  #container {
    min-height:100%;
    display:block;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
  }
  @media only screen and (max-width: 600px) {
    #device{
      border:1px solid rgb(56,128,255);
      border-radius:5px;
      width:100%;
      max-height: 100%;
      color:white;
      background-color:rgb(56,128,255);
      text-align:center;
      cursor:pointer;
      display:inline-block;
      font-size:20px;
    }
  }

  #device{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    max-width:85%;
    max-height: 100%;
    color:white;
    background-color:rgb(56,128,255);
    text-align:center;
    cursor:pointer;
    font-size:23px;
    display:inline-block;
    margin:30px;
  }
</style>